@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400&display=swap");

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  color: #222222;
}

h1 {
  font-size: 26px;
}

@media only screen and (max-width: 767px) {
  h1 {
    font-size: 22px;
  }
}

h2 {
  font-size: 24px;
}

@media only screen and (max-width: 767px) {
  h2 {
    font-size: 20px;
  }
}

h3 {
  font-size: 20px;
}

@media only screen and (max-width: 767px) {
  h3 {
    font-size: 18px;
  }
}

h4 {
  font-size: 18px;
}

@media only screen and (max-width: 767px) {
  h4 {
    font-size: 16px;
  }
}

h5 {
  font-size: 16px;
}

@media only screen and (max-width: 767px) {
  h5 {
    font-size: 14px;
  }
}

h6 {
  font-size: 14px;
}

@media only screen and (max-width: 767px) {
  h6 {
    font-size: 14px;
  }
}

p {
  color: #2F2F2F;
  font-size: 14px;
}

small {
  color: #2F2F2F;
  font-size: 12px;
}

.c-pointer {
  cursor: pointer;
}

.fs-13 {
  font-size: 13px;
}

.fs-11 {
  font-size: 11px;
}

.fw-med {
  font-weight: 500;
}

.page-title {
  font-size: 22px;
  font-weight: 400;
}

.text-gray {
  color: #737373;
}

.btn-asphalt,
.btn-cancel {
  background: #08213A;
  color: #FFF;
  font-size: 14px;
  padding: 12px 30px;
  border-radius: 35px;
}

.btn-asphalt.min-w,
.btn-cancel.min-w {
  width: 185px;
}

.btn-asphalt:hover,
.btn-cancel:hover {
  background: #021323;
  color: #FFF;
}

.btn-asphalt.linkedin-btn,
.btn-cancel.linkedin-btn {
  background: #2867B2;
  color: #FFF;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 10px 20px;
}

.btn-asphalt.linkedin-btn:hover,
.btn-cancel.linkedin-btn:hover {
  background: #184b89;
}

.btn-asphalt.btn-gray,
.btn-cancel.btn-gray {
  background: #E3E3E3;
  color: #2F2F2F;
}

.btn-asphalt.btn-gray:hover,
.btn-cancel.btn-gray:hover {
  background: #cfcfcf;
}

.btn-cancel {
  background: #FFF;
  color: rgba(115, 115, 115, 0.6);
}

.btn-cancel:hover {
  background: rgba(115, 115, 115, 0.2);
  color: #2F2F2F;
}

.min-h-unset {
  min-height: unset !important;
}

body {
  background: #FFF;
  font: 400 13px "DM Sans", sans-serif;
  color: #2F2F2F;
}

.left-wrap {
  background: #08213A;
  color: #FFF;
  min-height: 100vh;
  padding: 90px 70px 0;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: space-between;
}

.left-wrap .nav-brand {
  width: 195px;
}

.left-wrap h1 {
  font-weight: 400;
  font-size: 30px;
  color: #FFF;
  margin-top: 110px;
}

.left-wrap p {
  font-size: 16px;
  line-height: 27px;
  color: rgba(255, 255, 255, 0.6);
  width: 70%;
}

.left-wrap .btm-img {
  max-width: 80%;
  margin-top: 30px;
}

.left-wrap .nav-pills .nav-link {
  display: flex;
  align-items: center;
  padding: 0;
  opacity: 0.4;
  color: #FFF;
  font-size: 14px;
  margin-bottom: 30px;
  position: relative;
}

.left-wrap .nav-pills .nav-link .circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  background: #08213A;
  border: 1px dashed #FFF;
  border-radius: 50%;
  margin-right: 13px;
}

.left-wrap .nav-pills .nav-link .circle span {
  font-size: 18px;
  color: #FFF;
  visibility: hidden;
}

.left-wrap .nav-pills .nav-link .circle em {
  display: none;
}

.left-wrap .nav-pills .nav-link:after {
  content: "";
  position: absolute;
  left: 13px;
  bottom: 34px;
  width: 1px;
  height: 14px;
  background: #FFF;
}

.left-wrap .nav-pills .nav-link:first-child:after {
  display: none;
}

.left-wrap .nav-pills .nav-link.active {
  opacity: 1;
  background: transparent;
}

.left-wrap .nav-pills .nav-link.active .circle {
  background: #0CAD7D;
  border: none;
}

.left-wrap .nav-pills .nav-link.active .circle span {
  visibility: visible;
}

@media only screen and (max-width: 767px) {
  .left-wrap .nav-pills {
    flex-direction: row !important;
  }

  .left-wrap .nav-pills .nav-link {
    margin-bottom: 15px;
  }

  .left-wrap .nav-pills .nav-link:after {
    transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    left: -7px;
    bottom: 11px;
    width: 1px;
    height: 8px;
  }

  .left-wrap .nav-pills .nav-link .circle {
    width: 30px;
    height: 30px;
  }

  .left-wrap .nav-pills .nav-link .circle .ico {
    display: none;
  }

  .left-wrap .nav-pills .nav-link .circle em {
    display: inline-block;
    font-style: normal;
  }
}

@media only screen and (max-width: 1200px) {
  .left-wrap {
    padding: 90px 40px 0;
  }

  .left-wrap p {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .left-wrap {
    padding: 40px 8% 150px;
    min-height: unset;
  }

  .left-wrap .nav-brand {
    width: 130px;
  }

  .left-wrap h1 {
    margin-top: 50px;
    font-size: 24px;
  }

  .left-wrap p {
    font-size: 13px;
    line-height: 22px;
  }
}

.right-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-wrap p.lead {
  color: #737373;
  font-size: 14px;
}

@media only screen and (max-width: 767px) {
  .right-wrap p.lead br {
    display: none;
  }
}

.right-wrap .form-wrap {
  width: 445px;
  margin: 60px 0;
}

@media only screen and (max-width: 1200px) {
  .right-wrap .form-wrap {
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .right-wrap {
    position: relative;
    top: 0;
    width: 84%;
    left: 8%;
    margin-top: -140px;
    background: #FFF;
    border-radius: 5px;
    box-shadow: 0 0 8px rgba(47, 47, 47, 0.1);
    margin-bottom: 30px;
  }

  .right-wrap .form-wrap {
    width: 93%;
    margin: 20px 0 20px;
  }
}

.custom-form .form-label {
  font-size: 13px;
  color: #2F2F2F;
  margin-bottom: 5px;
}

.custom-form .form-label .astrick {
  color: #FF605F;
  font-size: 12px;
  margin-left: 2px;
}

.custom-form .form-label small {
  font-size: 10px;
}

.custom-form .form-control {
  font-size: 14px;
  color: #2F2F2F;
  height: 45px;
  border: 1px solid #E3E3E3;
  border-radius: 4px;
}

.custom-form .form-control:focus {
  border-color: #E3E3E3;
  box-shadow: 0 0 7px rgba(47, 47, 47, 0.09);
}

.custom-form .form-control.error {
  box-shadow: 0 0 8px rgba(255, 96, 95, 0.15);
  border-color: rgba(255, 96, 95, 0.2);
}

.custom-form .form-control.otp-input {
  width: 55px;
  height: 55px;
  border: none;
  box-shadow: 0 2px 14px rgba(47, 47, 47, 0.12);
  margin: 10px 10px;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
}

@media only screen and (max-width: 767px) {
  .custom-form .form-control.otp-input {
    width: 50px;
    height: 50px;
    margin: 10px 8px;
  }
}

.custom-form .input-group.with-ico .form-control {
  position: relative;
  border-radius: 4px !important;
  margin-left: 0 !important;
  padding-right: 50px;
}

.custom-form .input-group.with-ico .input-group-text {
  color: #737373;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: none;
  background: none;
  z-index: 99;
  margin-right: 20px !important
}

.custom-form .input-group.with-ico .input-group-text span {
  font-size: 22px;
}

.custom-form .input-group.two-input-ico .input-group-text {
  background: transparent;
  border-color: #E3E3E3;
  font-size: 14px;
}

.custom-form .input-group.two-input-ico .input-group-text+.form-control {
  border-left: none;
}

.custom-form .form-check .form-check-input {
  width: 18px;
  height: 18px;
  border-color: #E3E3E3;
}

.custom-form .form-check .form-check-input:checked {
  background-color: #08213A;
  border-color: #08213A;
}

.custom-form .form-check .form-check-input:focus {
  box-shadow: none;
}

.custom-form .form-check.tc label {
  color: #737373;
  margin-left: 7px;
  margin-top: 4px;
}

.custom-form .form-check.tc label a {
  color: #222222;
}

.custom-form .form-check.tc label a:hover {
  text-decoration: none;
}

.custom-form .checkbox-wrap .form-check-inline {
  display: inline-block;
  align-items: center;
}

.custom-form .checkbox-wrap .form-check-inline .form-check-input:checked[type=radio] {
  background-color: #FFF;
  background-image: url("../../../public/media/images/radio-dot.svg");
  background-size: 9px;
}

.custom-form .checkbox-wrap .form-check-inline .form-check-label {
  font-size: 13px;
  color: #2F2F2F;
  margin-left: 7px;
  margin-top: 4px;
}

.custom-form p {
  color: #737373;
}

.custom-form p a {
  color: #222222;
}

.custom-form p a:hover {
  text-decoration: none;
}

.custom-form p.error-msg {
  color: #FF605F;
  font-size: 11px;
  margin-top: 2px;
}

.custom-form textarea {
  resize: none;
}

.add-profile .left-wrap {
  padding-top: 70px;
}

@media only screen and (max-width: 767px) {
  .add-profile .left-wrap {
    padding-top: 40px;
  }
}

.right-wrap {
  align-items: flex-start;
}

.right-wrap .form-wrap {
  width: 100%;
  margin: 50px 80px;
}

.right-wrap .form-wrap .role {
  display: flex;
  align-items: center;
}

.right-wrap .form-wrap .role p {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
  flex-flow: column;
  color: #2F2F2F;
  font-size: 13px;
  cursor: pointer;
  padding: 15px 18px;
  border-radius: 4px;
  margin-right: 10px;
  opacity: 0.8;
  text-align: center;
}

.right-wrap .form-wrap .role p span {
  font-size: 28px;
  margin-bottom: 8px;
}

.right-wrap .form-wrap .role p.active,
.right-wrap .form-wrap .role p:hover {
  opacity: 1;
  background: #08213A;
  color: #FFF;
}

.right-wrap .form-wrap .render-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  border: 1px solid #E3E3E3;
  border-radius: 4px;
}

.right-wrap .form-wrap .render-switch span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  color: #737373;
  height: 100%;
  position: relative;
  z-index: 99;
}

.right-wrap .form-wrap .render-switch span:after {
  content: "";
  background: #08213A;
  position: absolute;
  left: 4px;
  top: 4px;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border-radius: 4px;
  z-index: -9;
  opacity: 0;
  transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
}

.right-wrap .form-wrap .render-switch span:hover:after,
.right-wrap .form-wrap .render-switch span.active:after {
  opacity: 1;
}

.right-wrap .form-wrap .render-switch span:hover,
.right-wrap .form-wrap .render-switch span.active {
  color: #FFF;
}

.right-wrap .form-wrap .add-btn {
  position: absolute;
  right: -18px;
  width: auto;
  padding: 0;
  height: auto;
  display: flex;
  bottom: 11px;
  text-decoration: none;
  color: rgba(115, 115, 115, 0.8);
}

.right-wrap .form-wrap .add-btn span {
  font-size: 22px;
  transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
}

.right-wrap .form-wrap .add-btn:hover {
  color: #2F2F2F;
}

.right-wrap .form-wrap .add-btn.delete {
  color: #FF605F;
}

.right-wrap .form-wrap .add-btn.delete:hover {
  color: #ff0000;
}

.right-wrap .form-wrap .file-drag-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  border: 1px dashed #E3E3E3;
  font-size: 11px;
  border-radius: 4px;
  height: 120px;
}

.right-wrap .form-wrap .file-drag-container .ico {
  font-size: 32px;
  margin-bottom: 7px;
  color: #737373;
}

.right-wrap .form-wrap .file-drag-container p {
  font-size: 12px;
  color: #737373;
  margin-bottom: 0;
}

.right-wrap .form-wrap .file-drag-container p span {
  color: #2F2F2F;
  cursor: pointer;
  text-decoration: underline;
}

.right-wrap .form-wrap .file-drag-container .default-file-input {
  opacity: 0;
  width: 44px;
  margin-left: -43px;
  position: relative;
  left: 39px;
  z-index: 9;
  cursor: pointer;
}

.right-wrap .form-wrap .hint {
  font-size: 11px;
  color: #737373;
  font-style: italic;
}

.right-wrap .form-wrap .btn-group .btn:first-child {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.right-wrap .form-wrap .btn-group .btn:last-child {
  margin-left: 0;
}

.right-wrap .form-wrap .uploaded-file {
  display: inline-flex;
  align-items: center;
  position: relative;
  background: rgba(227, 227, 227, 0.4);
  border-radius: 4px;
  padding: 10px 30px 10px 10px;
}

.right-wrap .form-wrap .uploaded-file img {
  width: 23px;
  margin-right: 12px;
}

.right-wrap .form-wrap .uploaded-file .circle-ico {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -12px;
  top: -12px;
  background: #FFF;
  color: #FF605F;
  padding: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow: 0 0 6px rgba(47, 47, 47, 0.13);
  transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
}

.right-wrap .form-wrap .uploaded-file .circle-ico span {
  font-size: 20px;
}

.right-wrap .form-wrap .uploaded-file .circle-ico:hover {
  background: #FF605F;
  color: #FFF;
}

.right-wrap .custom-form {
  min-height: calc(100vh - 180px);
  position: relative;
  padding-bottom: 70px;
}

.right-wrap .custom-form .btm-float {
  position: absolute;
  right: 0;
  bottom: 0;
}

.right-wrap .uploaded-profile {
  display: inline-flex;
  box-shadow: 0 2px 6px rgba(47, 47, 47, 0.13);
  position: relative;
}

.right-wrap .uploaded-profile .pic-sec {
  display: flex;
  width: 175px;
  height: 175px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.right-wrap .uploaded-profile .circle-ico {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -12px;
  top: -12px;
  background: #FFF;
  color: #FF605F;
  padding: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow: 0 0 6px rgba(47, 47, 47, 0.13);
  transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
}

.right-wrap .uploaded-profile .circle-ico span {
  font-size: 20px;
}

.right-wrap .uploaded-profile .circle-ico:hover {
  background: #FF605F;
  color: #FFF;
}

@media only screen and (max-width: 1199px) {
  .right-wrap .form-wrap {
    margin-left: 40px;
    margin-right: 40px;
  }
}

@media only screen and (max-width: 991px) {
  .right-wrap .form-wrap {
    margin-left: 30px;
    margin-right: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .right-wrap .form-wrap {
    margin: 20px 0;
    padding: 0 5px;
  }

  .right-wrap .form-wrap .role p {
    padding: 15px;
    min-height: 100px;
    margin-right: 5px;
  }

  .right-wrap .form-wrap .custom-form {
    min-height: unset;
  }

  .right-wrap .form-wrap .custom-form .btm-float .btn {
    padding-left: 20px;
    padding-right: 20px;
  }

  .right-wrap .form-wrap .custom-form .addon-group {
    background: rgba(115, 115, 115, 0.1);
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 4px;
  }

  .right-wrap .form-wrap .custom-form .addon-group .add-btn {
    bottom: -11px;
    top: auto;
    background: #FFF;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 8px rgba(115, 115, 115, 0.2);
    right: -9px;
  }

  .right-wrap .form-wrap .custom-form .addon-group .add-btn.delete {
    bottom: auto;
    top: -11px;
  }
}

header .nav-brand {
  width: 110px;
  margin: 10px 0;
}

header .navbar {
  background: #08213A;
}

header .navbar .navbar-collapse {
  flex-grow: unset;
}

header .navbar .navbar-collapse .navbar-nav .nav-item {
  margin-right: 40px;
}

header .navbar .navbar-collapse .navbar-nav .nav-item:last-child {
  margin-right: 0;
}

header .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
  font-size: 14px;
  color: #FFF;
}

header .navbar .profile-menu .btn {
  display: flex;
  align-items: center;
  border: none;
  border-left: 1px solid rgba(255, 255, 255, 0.13);
  border-radius: 0;
  padding: 8px 20px;
  margin-left: 20px;
}

header .navbar .profile-menu .btn:active {
  border: none;
  border-left: 1px solid rgba(255, 255, 255, 0.13);
}

header .navbar .profile-menu .btn .img-wrap {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid rgba(227, 227, 227, 0.2);
  margin-right: 8px;
}

header .navbar .profile-menu .btn span {
  color: #FFF;
  font-size: 14px;
}

header .navbar .profile-menu .btn:after {
  filter: invert(1);
}

header .navbar .profile-menu .dropdown-menu .dropdown-item {
  font-size: 13px;
}

@media only screen and (max-width: 991px) {
  header .navbar .navbar-collapse .navbar-nav .nav-item {
    margin-right: 15px;
  }

  header .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
    font-size: 12px;
  }

  header .navbar .profile-menu .btn span {
    font-size: 12px;
  }
}

@media only screen and (max-width: 767px) {
  header .nav-brand {
    width: 90px;
  }

  header .navbar .hdr-right {
    flex-flow: row-reverse;
  }

  header .navbar .hdr-right .profile-menu .btn {
    border: none;
    padding: 10px;
    margin-left: 0;
  }

  header .navbar .hdr-right .profile-menu .btn span {
    display: none;
  }

  header .navbar .hdr-right .profile-menu .btn::after {
    display: none;
  }

  header .navbar .hdr-right .navbar-toggler {
    filter: brightness(0) invert(1);
    border-radius: 0;
    border-width: 0;
    border-left-width: 1px;
  }

  header .navbar .hdr-right .navbar-toggler:focus {
    box-shadow: none;
  }

  header .navbar .hdr-right .navbar-collapse {
    position: absolute;
    left: 0px;
    top: 60px;
    background: #021323;
    width: 100%;
    z-index: 99;
  }

  header .navbar .hdr-right .navbar-collapse .navbar-nav .nav-item {
    margin: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.13);
  }

  header .navbar .hdr-right .navbar-collapse .navbar-nav .nav-item .nav-link {
    padding: 13px 20px;
  }
}

.content-sec {
  background: #FAFAFA;
  min-height: calc(100vh - 56px);
}

.toggle {
  position: relative;
  overflow: hidden;
}

.toggle input[type=checkbox] {
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 75px;
  height: 27px;
  background: rgba(115, 115, 115, 0.4);
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
}

.toggle input[type=checkbox]:checked[type=checkbox] {
  background: #0CAD7D;
}

.toggle input[type=checkbox]:checked[type=checkbox]:after {
  left: 51px;
  background: #FFF url("../../../public/media/images/tick-ico.svg") center center no-repeat;
}

.toggle input[type=checkbox]:checked[type=checkbox]~label.yes {
  opacity: 1;
  left: 20px;
}

.toggle input[type=checkbox]:not(:checked[type=checkbox])~label.no {
  opacity: 1;
  left: 36px;
}

.toggle input[type=checkbox]::after {
  position: absolute;
  content: "";
  left: 3px;
  top: 3px;
  width: 21px;
  height: 21px;
  background: #FFF url("../../../public/media/images/minus-ico.svg") center center no-repeat;
  border-radius: 50%;
  transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
}

.toggle label {
  position: absolute;
  pointer-events: none;
  color: #FFF;
  transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
  opacity: 0;
}

.toggle label.no {
  left: 30px;
  top: 5px;
}

.toggle label.yes {
  left: 24px;
  top: 5px;
}

.profile-content .snippet {
  background: #FFF;
  border: 1px solid rgba(227, 227, 227, 0.8);
  border-radius: 4px;
  padding: 25px;
}

.profile-content .snippet .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}

.profile-content .snippet .head a {
  color: #737373;
  font-size: 12px;
}

.profile-content .snippet .head a:hover {
  text-decoration: none;
}

.profile-content .snippet .cont .ico-row {
  justify-content: flex-start;
  display: flex;
  align-items: center;
}

.profile-content .snippet .cont .ico-row .ico {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background: rgba(8, 33, 58, 0.1);
  border-radius: 50%;
}

.profile-content .snippet .cont .ico-row .ico span {
  font-size: 16px;
}

.profile-content .snippet .cont .ico-row p {
  margin-left: 12px;
}

.profile-content .snippet .cont .badge-sec .chips {
  background: rgba(8, 33, 58, 0.1);
  border-radius: 4px;
  padding: 8px 10px;
  color: #08213A;
  font-size: 12px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.profile-content .snippet .cont .badge-sec .chips small {
  font-size: 12px;
  color: rgba(8, 33, 58, 0.5);
  display: block;
}

.profile-content .snippet .cont .expect-salary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 20px;
  background: rgba(8, 33, 58, 0.1);
  border-radius: 4px;
}

.profile-content .snippet .cont .with-dot li {
  font-size: 12px;
}

.profile-content .snippet .cont .with-dot li:after {
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  background: #737373;
  border-radius: 50%;
  margin: 0 4px 0 6px;
  position: relative;
  top: -1px;
}

.profile-content .snippet .cont .with-dot li:last-child:after {
  display: none;
}

.profile-content .snippet .cont .with-dot li:not(:last-child) {
  margin-right: 0;
}

.profile-content .snippet .cont .with-dot.fs-13 li {
  font-size: 13px;
}

.profile-content .snippet .cont.profile-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 45px;
}

.profile-content .snippet .cont.profile-sec .avatar-sec {
  display: flex;
  align-items: center;
  width: calc(100% - 180px);
}

.profile-content .snippet .cont.profile-sec .avatar-sec .img-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 94px;
  height: 94px;
  margin-right: 20px;
  border-radius: 50%;
  overflow: hidden;
  -o-object-fit: cover;
  object-fit: cover;
  border: 3px solid #FFF;
  box-shadow: 0 0 4px rgba(47, 47, 47, 0.2);
}

.profile-content .snippet .cont.profile-sec .avatar-sec .avatar-cont {
  width: calc(100% - 120px);
}

@media only screen and (max-width: 1199px) {
  .profile-content .snippet .cont.profile-sec h2 {
    font-size: 18px;
  }

  .profile-content .snippet .cont.profile-sec .with-dot li {
    font-size: 11px;
  }
}

@media only screen and (max-width: 767px) {
  .profile-content .snippet .cont.profile-sec {
    justify-content: flex-start;
    flex-flow: column;
    margin-bottom: 0;
  }

  .profile-content .snippet .cont.profile-sec .avatar-sec {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    width: 100%;
  }

  .profile-content .snippet .cont.profile-sec .avatar-sec .img-wrap {
    margin: 0;
  }

  .profile-content .snippet .cont.profile-sec .avatar-sec .avatar-cont {
    margin-top: 20px;
    width: 100%;
    text-align: center;
  }
}

.profile-content .snippet .cont .exp-timeline .timeline-item {
  position: relative;
  padding-bottom: 35px;
  padding-left: 20px;
}

.profile-content .snippet .cont .exp-timeline .timeline-item:before {
  content: "";
  position: absolute;
  left: 0;
  top: 7px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #08213A;
}

.profile-content .snippet .cont .exp-timeline .timeline-item:after {
  content: "";
  position: absolute;
  left: 3px;
  top: 20px;
  bottom: -1px;
  background: rgba(8, 33, 58, 0.2);
  width: 1px;
}

.profile-content .snippet .cont .exp-timeline .timeline-item:last-child:after {
  display: none;
}

.profile-content .snippet .cont .resume-snip {
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  background: rgba(8, 33, 58, 0.1);
  padding: 10px;
}

.profile-content .snippet .cont .resume-snip .ico {
  width: 23px;
  margin-right: 12px;
}

@media only screen and (max-width: 767px) {
  .profile-content .snippet {
    padding: 20px;
  }
}

.profile-content .pill-navbar {
  margin-top: -48px;
}

.profile-content .pill-navbar .nav-pills {
  width: 100%;
  display: flex;
  align-items: center;
}

.profile-content .pill-navbar .nav-pills .nav-item {
  width: 25%;
  display: flex;
  align-items: center;
}

.profile-content .pill-navbar .nav-pills .nav-item .nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #737373;
  padding-bottom: 15px;
  border-radius: 0;
  border-bottom: 2px solid transparent;
}

.profile-content .pill-navbar .nav-pills .nav-item .nav-link .ico {
  font-size: 22px;
  margin-right: 7px;
}

.profile-content .pill-navbar .nav-pills .nav-item .nav-link.active {
  background: transparent;
  border-bottom-color: #08213A;
  color: #08213A;
}

@media only screen and (max-width: 1199px) {
  .profile-content .pill-navbar {
    margin-top: -44px;
  }

  .profile-content .pill-navbar .nav-pills .nav-item .nav-link {
    font-size: 12px;
  }

  .profile-content .pill-navbar .nav-pills .nav-item .nav-link .ico {
    font-size: 18px;
    margin-right: 4px;
  }
}

@media only screen and (max-width: 991px) {
  .profile-content .pill-navbar {
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .profile-content .pill-navbar {
    margin-bottom: -7px;
  }
}

footer {
  padding-top: 8px;
  padding-bottom: 8px;
  background: #FFF;
  border-top: 1px solid rgba(227, 227, 227, 0.8);
}

footer p {
  font-size: 11px;
  text-align: center;
  color: rgba(115, 115, 115, 0.6);
}

.left-search-wrap {
  background: #FFF;
  padding: 20px 20px 80px 20px;
  border-radius: 4px;
  box-shadow: 0 4px 15px rgba(34, 34, 34, 0.08);
  position: relative;
}

.left-search-wrap .custom-form .form-label {
  font-size: 12px;
}

.left-search-wrap .custom-form .form-control {
  font-size: 13px;
}

.left-search-wrap .btm-btn {
  border-top: 1px solid #E3E3E3;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  align-items: center;
}

.left-search-wrap .btm-btn .btn {
  width: 48%;
}

@media only screen and (max-width: 991px) {
  .left-search-wrap .custom-form {
    height: 330px;
    overflow-y: auto;
  }
}

.result-listing .title {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.result-listing .title .sort-by {
  display: flex;
  align-items: center;
}

.result-listing .title .sort-by .form-label {
  white-space: nowrap;
  font-size: 13px;
  margin-bottom: 0;
  color: #737373;
}

.result-listing .title .sort-by .form-select {
  background-color: transparent;
  font-size: 13px;
  color: #737373;
  padding: 0 14px 0 4px;
  border: none;
  background-position: right 5px;
  background-size: 10px;
}

.result-listing .candidate-snippet {
  background: #FFF;
  border: 1px solid #E3E3E3;
  border-radius: 4px;
}

.result-listing .candidate-snippet .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 25px;
  border-bottom: 1px solid #E3E3E3;
}

.result-listing .candidate-snippet .head .avatar-encl {
  display: flex;
  align-items: center;
  width: 50%;
}

.result-listing .candidate-snippet .head .avatar-encl .avatar-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 2px solid #FFF;
  box-shadow: 0 0 4px rgba(47, 47, 47, 0.2);
  overflow: hidden;
  -o-object-fit: cover;
  object-fit: cover;
  margin-right: 8px;
}

.result-listing .candidate-snippet .head .avatar-encl .avatar-detail {
  display: flex;
  justify-content: flex-start;
  flex-flow: column;
  width: calc(100% - 80px);
}

.result-listing .candidate-snippet .head .btn-encl {
  display: flex;
  align-items: center;
}

.result-listing .candidate-snippet .head .btn-encl .btn {
  font-size: 12px;
  padding: 10px 20px;
  min-height: 34px;
}

.result-listing .candidate-snippet .cont {
  padding: 20px 25px;
}

.result-listing .candidate-snippet .cont .badge {
  margin: 0 5px 5px 0;
}

.result-listing .candidate-snippet .cont .badge.tech {
  background: rgba(8, 33, 58, 0.15);
  padding: 5px 10px;
  color: #2F2F2F;
  font-size: 12px;
  border-radius: 4px;
  font-weight: 400;
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .result-listing .candidate-snippet .head {
    padding: 15px;
    flex-flow: column;
    align-items: flex-start;
  }

  .result-listing .candidate-snippet .head .avatar-encl {
    width: 100%;
    margin-bottom: 15px;
  }

  .result-listing .candidate-snippet .cont {
    padding: 15px;
  }
}

.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  min-height: calc(100vh - 130px);
}

.no-data p {
  font-size: 13px;
  color: #737373;
}

.pagination .page-item {
  display: flex;
  align-items: center;
}

.pagination .page-item .page-link {
  border: none;
  background: transparent;
  font-size: 13px;
  color: #737373;
}

.pagination .page-item .page-link.btn-asphalt {
  background: #08213A;
  padding: 8px 25px;
  border-radius: 30px;
  color: #FFF;
}

.pagination .page-item .page-link:focus {
  box-shadow: none;
  outline: none;
}

.pagination .page-item.disabled .page-link.btn-asphalt {
  color: #737373;
  background: transparent;
}

.pagination .page-item.active .page-link {
  text-decoration: underline;
  color: #08213A;
  font-weight: 500;
}

/*# sourceMappingURL=custom-style.css.map */
.divHide {
  display: none
}

.fieldError {
  color: #ff0000 !important;
}

.form-control:focus {

  border-color: #E3E3E3;
  box-shadow: 0 0 7px rgba(47, 47, 47, 0.09);
}

.form-check-input:checked {
  width: 18px;
  height: 18px;
  background-color: #08213a;
  border-color: #08213a;
}

.form-check .form-check-input {
  width: 18px;
  height: 18px;
  border-color: #08213a;
}

.form-check-label {
  font-size: 13px;
  color: #2F2F2F;
  margin-left: 7px;
  margin-top: 4px;
}