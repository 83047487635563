$color_1: gray;

.main {
  text-align: center;
  margin-top: 200px;
}
.splash {
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 24px;
    margin-bottom: 16px;
  }
  h2 {
    font-size: 18px;
    color: $color_1;
  }
}
